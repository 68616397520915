<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" @onCreateNew="$router.push('/routes/create')"/>
    <v-main>
      <!-- <v-row class="mb-4">
        <v-col cols="4" v-for="route in routes" :key="route.id">
          <RouteCard 
            :route="route" 
            @cardClicked="goToEditRoutePage(route.id)" 
            @duplicate="duplicateRoute"
          />
        </v-col>
      </v-row> -->
      <div class="main-card mb-3 card">
        <div class="card-body">
          <div v-if="loadingRoutes" class="text-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div class="list-group" flat accordion>
            <RouteListItem 
              @itemClicked="goToEditRoutePage(route.id)"
              @duplicate="duplicateRoute"
              v-for="route in routes"
              :key="route.id"
              :route="route"
            />
          </div>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle2.vue";
import BaseCard from '@/Layout/Components/BaseCard.vue';
import RouteCard from './RouteCard.vue';

import service from '@/service/coreService';
import RouteListItem from './RouteListItem.vue';

export default {
  name: 'RoutesPage',
  components: {
    PageTitle,
    BaseCard,
    RouteCard,
    RouteListItem,
  },
  data() {
    return {
      heading: this.$t('menu.routes'),
      subheading: '',
      routes: [],
      loadingRoutes: false,
    }
  },
  methods: {
    getRoutes() {
      this.loadingRoutes = true;
      service.routes.getRoutes().then(response => {
        this.routes = response.data;
      })
      .finally(() => {
        this.loadingRoutes = false;
      });
    },
    goToEditRoutePage(routeId) {
      this.$router.push({ name: 'routesCreate', params: { routeId }})
    },
    duplicateRoute(route) {
      delete route.id;
      route.name = 'Cópia de ' + route.name;
      for(stop of route.stops) {
        delete stop.id;
      }
      service.routes.saveRoute(route).then(() => {
        this.getRoutes();
      })
    }
  },
  mounted() {
    this.getRoutes();
  }
}
</script>

<style>

</style>