<template>
  <div>
    <PageTitle
      :heading="heading"
      :subheading="subheading"
      @onCreateNew="openCreateUserDialog({})"
      :createNewButton="authorities.includes('write:user')"
    ></PageTitle>
    <CreateUserDialog ref="createUserDialog" @updateUsers="getUsers"/>
    <div v-if="authorities.includes('read:user')" class="main-card mb-3 card">
      <div class="card-body">
        <div v-if="loadingUsers" class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-expansion-panels v-else class="list-group" flat accordion>
          <UserListItem
            v-for="user in users"
            :key="user.id"
            :user="user"
            @updateUsers="getUsers"
          />
        </v-expansion-panels>
      </div>
    </div>
    <div v-else>
      <h4>Você não tem permissão para visitar esta página.</h4>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle2.vue";
import UserListItem from './UserListItem.vue';
import CreateUserDialog from './CreateUserDialog.vue';

import service from '@/service/coreService';
import { mapState } from 'vuex';

export default {
  name: 'UsersPage',
  components: {
    PageTitle,
    UserListItem,
    CreateUserDialog
  },
  data() {
    return {
      heading: this.$t('menu.users'),
      subheading: '',
      users: [],
      loadingUsers: false,
    }
  },
  computed: {
    ...mapState({
      authorities: state => state.auth.user.authorities
    })
  },
  methods: {
    getUsers() {
      this.loadingUsers = true;
      service.users.getUsers().then(response => {
        this.users = response.data;
      })
      .finally(() => {
        this.loadingUsers = false;
      });
    },
    openCreateUserDialog(user) {
      this.$refs.createUserDialog.openDialog(user);
    }
  },
  mounted() {
    if(this.authorities.includes('read:user')) {
      this.getUsers();
    }
  }
}
</script>
