<template>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div>
          {{heading}}
          <div class="page-title-subheading">{{subheading}}</div>
        </div>
      </div>
      <div class="page-title-actions" v-if="createNewButton">
        <button 
          type="button"
          class="btn-shadow d-inline-flex align-items-center btn btn-success"
          @click="$emit('onCreateNew')"
        >
          <font-awesome-icon class="mr-2" icon="plus" />{{ $t('create_new_btn') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faStar, faPlus);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    createNewButton: {
      type: Boolean,
      default: true,
    },
    icon: String,
    heading: String,
    subheading: String
  }
};
</script>

<style scoped>
button {
  color: white;
}
</style>
