<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>
        {{ $t('confirm_delete_image', { txt: name }) }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="default"
          text
          @click="closeDialog"
        >{{ $t('btn_cancel') }}
        </v-btn>
        <v-btn
          color="red"
          text
          @click="confirmDelete"
        >{{ $t('btn_delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteImageDialog',
  data() {
    return {
      show: false,
      name: ''
    }
  },
  methods: {
    openDialog(name) {
      this.name = name;
      this.show = true;
    },
    closeDialog() {
      this.name = '';
      this.show = false;
    },
    confirmDelete() {
      this.$emit('deleteImage');
      this.closeDialog();
    }
  }
}
</script>

<style>

</style>