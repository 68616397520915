import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store';

const url = process.env.VUE_APP_CORE_API;

const instance = axios.create({
  baseURL: url,
  headers: {
    'Application': 'portal',
  }
});

instance.interceptors.request.use(config => {
  const user = JSON.parse(Cookies.get('user')).user;
  config.headers['Authorization'] = 'Bearer ' + Cookies.get('token');
  config.headers['Company-Id'] = user.companyId;
  return config;
});

instance.interceptors.response.use((response) => response, (error) => {
  if(error.response.status === 403) {
    store.commit('LOGOUT');
  }
  return Promise.reject(error);
});

export default {
  vehicles: {
    getVehicles() {
      return instance.get('/vehicles');
    },
    saveVehicle(vehicle) {
      return instance.post('/vehicles', vehicle);
    },
    deleteVehicle(vehicleId) {
      return instance.delete('/vehicles/' + vehicleId);
    },
    updateVehicle(vehicle) {
      return instance.put('/vehicles', vehicle)
    },
    getImage(vehicleId) {
      return instance.get('/images/vehicles/' + vehicleId);
    },
    saveImage(vehicleId, image) {
      return instance.post('/images/vehicles/' + vehicleId, {image});
    },
    deleteImage(vehicleId) {
      return instance.delete('/images/vehicles/' + vehicleId);
    }
  },
  drivers: {
    getDrivers() {
      return instance.get('/drivers');
    },
    saveDriver(driver) {
      return instance.post('/drivers', driver);
    },
    updateDriver(driver) {
      return instance.put('/drivers', driver);
    },
    getDriverById(driverId) {
      return instance.get('/drivers/' + driverId);
    },
    deleteDriver(driverId) {
      return instance.delete('/drivers/' + driverId);
    },
    getTravels(driverId, from , to) {
      return instance.get(`/drivers/${driverId}/travels`, { params: { from, to }});
    },
    getImage(driverId) {
      return instance.get('/images/drivers/' + driverId);
    }
  },
  passengers: {
    getPassengers() {
      return instance.get('/passengers');
    },
    updatePassenger(passenger) {
      return instance.put('/passengers', passenger);
    },
    getPassengerById(passengerId) {
      return instance.get('/passengers/' + passengerId);
    },
    getTravels(passengerId) {
      return instance.get(`/passengers/${passengerId}/travels`);
    },
    getImage(passengerId) {
      return instance.get('/images/passengers/' + passengerId);
    }
  },
  users: {
    getUsers() {
      return instance.get('/users');
    },
    saveUser(user) {
      return instance.post('/users', user);
    },
    getImage(userId) {
      return instance.get('/images/users/' + userId);
    }
  },
  routes: {
    getRoutes() {
      return instance.get('/routes');
    },
    getRouteById(routeId) {
      return instance.get('/routes/' + routeId);
    },
    saveRoute(route) {
      return instance.post('/routes', route);
    },
    updateRoute(route) {
      return instance.put('/routes', route);
    },
    deleteRoute(routeId) {
      return instance.delete('/routes/' + routeId);
    }
  },
  travels: {
    getTravels(from, to) {
      return instance.get('/travels', { params: { from, to }});
    },
    saveTravel(travel) {
      return instance.post('/travels', travel);
    },
    updateTravel(travel) {
      return instance.put('/travels', travel);
    },
    updateRecurrentTravel(travel) {
      return instance.put('/travels/recurrent', travel);
    },
    getTravelSummary(from, to) {
      return instance.get('/travels/summary', { params: { from, to }})
    },
    deleteTravel(travelId) {
      return instance.delete('/travels/' + travelId);
    },
    deleteRecurrentTravels(travelModelId, from) {
      return instance.delete('/travels/models/' + travelModelId, { params: { from } })
    },
    getTravelById(travelId) {
      return instance.get('/travels/' + travelId);
    },
    getPassengersByTravelId(travelId) {
      return instance.get(`/travels/${travelId}/bookings`);
    }
  },
  company: {
    getCompanyById(companyId) {
      return instance.get('/company/' + companyId);
    }
  }
}