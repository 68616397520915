<template>
  <div class="widget-content p-0 mb-2">
    <div class="widget-content-wrapper">
      <div class="widget-content-left mr-3">
        <div class="widget-content-left">
          <v-avatar color="grey">
            <v-img :src="'data:image/jpeg;base64,'+image" alt/>
          </v-avatar>
        </div>
      </div>
      <div class="widget-content-left flex2">
        <div class="widget-heading">{{ title }}</div>
        <div class="widget-subheading">{{ subtitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '@/service/coreService';

export default {
  name: 'CustomTile',
  props: [
    'title',
    'subtitle',
    // vehicles, drivers, passengers or users
    'imageFromEntity',
    // entity id
    'imageName',
  ],
  data() {
    return {
      image: null
    }
  },
  methods: {
    getImage() {
      service[this.imageFromEntity].getImage(this.imageName).then(response => {
        if(!!response) {
          this.image = response.data;
        }
      })
    }
  },
  mounted() {
    this.getImage();
  }
}
</script>

<style>

</style>