import axios from 'axios';
import router from '@/router';
import Cookies from 'js-cookie';

const url = process.env.VUE_APP_CORE_API;

const instance = axios.create({
  baseURL: url,
  headers: {
    'Application': 'portal',
    'Company-Id': 1
  }
})

export default {
  state: () => { 
    const user = Cookies.get('user');
    return user ? 
    { isLoggedIn: true, user: JSON.parse(user) } :
    { isLoggedIn: false, user: null }
  },
  mutations: { 
    LOGIN(state, user) {
      state.user = user;
      state.isLoggedIn = true;

      Cookies.set('token', user.token);
      Cookies.set('user', JSON.stringify(user));
    },
    LOGOUT(state) {
      state.user = {};
      state.isLoggedIn = false;

      Cookies.remove('token');
      Cookies.remove('user');
      Cookies.remove('authorities');

      router.push('/login');
    },
    SAVE_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    }
  },
  actions: { 
    login({commit}, data) {
      return instance.post('/login', data).then(response => {
        commit('LOGIN', response.data);
        return Promise.resolve();
      })
    }
  },
  getters: {  }
}