<template>
  <v-expansion-panel class="list-group-item">
    <v-expansion-panel-header class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left mr-3">
          <div class="widget-content-left">
            <!-- <v-img width="42" class="rounded-circle" :src="'data:image/jpeg;base64,'+image" alt /> -->
            <v-avatar color="grey">
              <v-img :src="'data:image/jpeg;base64,'+image" alt/>
            </v-avatar>
          </div>
        </div>
        <div class="widget-content-left flex2">
          <div class="widget-heading">{{ driver.name }}</div>
          <div class="widget-subheading">{{ driver.email }}</div>
        </div>
        <!-- <span v-show="driver.inTravel">Em viagem</span> -->
        <v-spacer />
        <div @click.stop="" class="widget-content-right text-center mr-5">
          <!-- <span>{{ driver.rating.toFixed(1) }}</span> -->
          <v-rating
            half-increments
            readonly
            :value="4"
            color="warning" 
            background-color="warning"
          ></v-rating>
        </div>
        <div class="widget-content-right text-center mr-5">
          <div class="mb-2">{{ $t('menu.travels') }}</div>
          <div class="ml-auto badge badge-pill badge-success">{{ driver.numTravels }}</div>
        </div>
        <!-- <div class="widget-content-right text-center mr-3">
          <DriverDetailsDialog />
        </div> -->
    </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <DriverDetails :driver="driver" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import DriverDetails from './DriverDetails.vue';

import service from '@/service/coreService';

export default {
  name: 'DriverListItem',
  props: {
    driver: Object
  },
  components: {
    DriverDetails,
  },
  data() {
    return {
      image: null,
    }
  },
  mounted() {
    service.drivers.getImage(this.driver.id).then(response => {
      this.image = response.data;
    })
  }
}
</script>

<style>

</style>