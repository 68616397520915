<template>
  <v-card @click="$emit('cardClicked')">
    <LMap
      style="width: 100%; height: 200px"
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      :options="mapOptions"
      @ready="updateBounds"
    >
      <LMarker 
        v-for="stop in route.stops" :key="stop.id"
        :lat-lng="[stop.latitude, stop.longitude]"
      ></LMarker>
      <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
    </LMap>
    <v-card-text>
      <v-row>
        <v-col cols="10">
          <span class="title">
            {{ route.name }}
          </span>
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop="duplicate"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Duplicar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { latLngBounds } from 'leaflet'
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet'

export default {
  name: 'RouteCard',
  props: {
    route: Object
  },
  components: {
    ConfirmDialog,
    LMap,
    LMarker,
    LTileLayer,
  },
  data() {
    return {
      zoom: 5,
      center: [-27, -52],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        closePopupOnClick: false,
        boxZoom: false,
        doubleClickZoom: false,
        dragging: false,
        scrollWheelZoom: false,
      },
      bounds: latLngBounds( 
        { lat: -27.6, lng: -52.3 },
        { lat: -27.82991732677597, lng: -52.08716201782228 }
      ),
    }
  },
  methods: {
    updateBounds() {
      const bounds = latLngBounds(this.route.stops.map(stop => {
        return { lat: stop.latitude, lng: stop.longitude };
      }))
      this.bounds = bounds;
    },
    async duplicate() {
      let result = await this.$refs.confirmDialog.openDialog(`Criar uma cópia de ${this.route.name}?`);
      if(result) {
        this.$emit('duplicate', this.route);
      }
    }
  }
}
</script>

<style>

</style>