<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title class="headline">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field 
                  v-model="vehicle.name"
                  dense
                  :label="$t('name')"
                  type="text"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="vehicle.plate"
                  dense
                  :label="$t('vehicles.plate')"
                  type="text"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="vehicle.seats"
                  dense 
                  :label="$t('vehicles.seats')"
                  type="number"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-show="!vehicle.id">
                <v-file-input
                  v-model="vehicle.image"
                  dense
                  accept="image/*"
                  :label="$t('vehicles.photo_label')"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="default" @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
        <v-btn text color="primary" @click="save">{{ $t('btn_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from '@/service/coreService';
import {util} from '@/util/util';

export default {
  name: 'VehicleDialog',
  data() {
    return {
      show: false,
      isFormValid: false,
      formRules: [v => !!v || this.$t('fill_field_warning')],
      vehicle: {},
      image: null,
    }
  },
  computed: {
    dialogTitle() {
      return !!this.vehicle.id ? this.$t('vehicles.edit') : this.$t('vehicles.create');
    }
  },
  methods: {
    openDialog(vehicle, image) {
      this.vehicle = vehicle;
      this.image = image;
      this.show = true;
    },
    closeDialog() {
      this.show = false;
      this.vehicle = {};
    },
    save() {
      if(this.$refs.form.validate()) {
        if(!!this.vehicle.id) {
          this.updateVehicle();
        } else {
          this.saveNewVehicle();
        }
      }
    },
    async saveNewVehicle() {
      if(!!this.vehicle.image) {
        var finalImage = await util.toBase64(this.vehicle.image)
        this.vehicle.image = finalImage;
      }
      service.vehicles.saveVehicle(this.vehicle).then(() => {
        this.$emit('saveVehicle');
        this.closeDialog();
      })
    },
    updateVehicle() {
      service.vehicles.updateVehicle(this.vehicle).then(() => {
        this.$emit('saveVehicle');
        this.closeDialog();
      })
    }
  }
}
</script>
