<template>
    <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title class="headline">
        {{ $t('routes.create.stop.dialog_title') }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-container>
            <v-row>
              <v-col cols="12" class="mb-5">
                <v-text-field 
                  v-model="stop.name"
                  dense
                  :label="$t('routes.create.stop.name_label')"
                  :hint="$t('routes.create.stop.name_hint')"
                  persistent-hint
                  type="text"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="stop.stopType"
                  dense 
                  :label="$t('routes.create.stop.type_label')"
                  :items="stopTypes"
                  :rules="formRules"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="pickerMenu"
                  v-model="pickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="stop.arrivalTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      :rules="formRules"
                      v-model="stop.arrivalTime"
                      :label="$t('routes.create.stop.time_label')"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="pickerMenu"
                    v-model="stop.arrivalTime"
                    format="24hr"
                    no-title
                    full-width
                    @click:minute="$refs.pickerMenu.save(stop.arrivalTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="stop.stopAddress"
                  @change="addressChanged = true"
                  :search-input.sync="address"
                  :items="addresses"
                  :no-data-text="$t('routes.create.stop.no_address_found')"
                  @keyup="getAddressSuggestions"
                  dense
                  item-text="description"
                  item-value="description"
                  :label="$t('routes.create.stop.address_label')"
                  :rules="formRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="default" @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
        <v-btn text color="primary" :loading="loadingSave" @click="save">{{ $t('btn_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CreateStopDialogs',
  data() {
    return {
      show: false,
      formRules: [v => !!v || this.$t('fill_field_warning')],
      isFormValid: false,
      stopTypes: [
        { text: this.$t('routes.create.stop.boarding_type'), value: 'BOARDING' },
        { text: this.$t('routes.create.stop.landing_type'), value: 'LANDING' },
      ],
      stop: {},
      loadingSave: false,
      pickerMenu: false,
      addresses: [],
      address: '',
      addressChanged: false,
      isUpdate: false,
    }
  },
  watch: {
    show(newValue) {
      if(newValue === false) {
        this.stop = {};
        this.show = false;
        this.addressChanged = false;
        this.isUpdate = false;
        this.$refs.form.resetValidation();
      }
    }
  },
  methods: {
    openDialog(stop) {
      if(Object.keys(stop).length !== 0) {
        this.stop = {...stop};
        this.addresses = [stop.stopAddress];
        this.isUpdate = true;
      }
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    async save() {
      if(this.$refs.form.validate()) {
        this.loadingSave = true;
        if(this.addressChanged) {
          const addressResult = await this.getLatLng(this.stop.stopAddress);
          this.stop.latitude = addressResult.lat;
          this.stop.longitude = addressResult.lng;
        }
        if(this.isUpdate) {
          this.$emit('updateStop', this.stop);
        } else {
          this.$emit('saveStop', this.stop);
        }
      }
    },
    openPicker() {
      this.$refs.picker.open();
    },
    getAddressSuggestions() {
      const service = new google.maps.places.AutocompleteService()
      const request = {
        input: this.address,
        componentRestrictions: {
          country: 'br'
        }
      }
      service.getPlacePredictions(request, (predictions, status) => {
        if(status === google.maps.places.PlacesServiceStatus.OK) {
          this.addresses = predictions;
        } else {
          console.log(status);
        }
      })
    },
    async getLatLng(address) {
      const service = new google.maps.Geocoder();
      var location = {};
      await service.geocode({address}, result => {
        location.lat = result[0].geometry.location.lat()
        location.lng = result[0].geometry.location.lng()
      })
      return location;
    },
    setLoadingSave(value) {
      this.loadingSave = value;
    },
  },
}
</script>

<style>

</style>