<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" :createNewButton="false" />
    <BaseCard class="mb-5">
      <v-main>
        <v-form ref="basicInfoForm">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="travel.name"
                :rules="formRules"
                :label="$t('travels.register.id_label')"
                :hint="$t('travels.register.id_hint')"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="3">
             <v-switch
              v-model="travel.isActive"
              inset
              :label="$t('travels.register.active_label')"
            />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <CustomDatePicker 
                :initialDate="travel.date"
                :label="$t('travels.register.date_label')"
                :rules="formRules"
                @inputDate="travel.date = $event" 
              />
            </v-col>
            <v-col cols="4">
              <v-select
                :label="$t('travels.register.repeat_label')"
                :items="repeatItems"
                v-model="travel.repeat"
                :disabled="!!travel.id"
              ></v-select>
              <div v-if="travel.repeat === 'CUSTOM'" class="d-flex justify-center">
                <v-checkbox
                  v-for="day in weekDays"
                  :key="day.value"
                  v-model="travel.daysToRepeat"
                  :hint="day.text"
                  :value="day.value"
                  persistent-hint
                  :disabled="!!travel.id"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col v-if="travel.repeat !== 'NO_REPEAT'" cols="4">
              <CustomDatePicker
                :initialDate="travel.until"
                :label="$t('travels.register.until_label')" 
                :rules="formRules"
                :disabled="!!travel.id"
                @inputDate="travel.until = $event"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-stepper v-model="currentStep" class="mt-4">
          <v-stepper-header>
            <v-spacer />
            <v-stepper-step
              color="primary"
              :complete="currentStep > 1"
              step="1"
            >
              {{ $t('travels.outward') }}
            </v-stepper-step>

            <v-divider></v-divider>
            <!-- <v-spacer /> -->

            <v-stepper-step
              color="primary"
              step="2"
            >
              {{ $t('travels.return') }}
            </v-stepper-step>
            <v-spacer />
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              
              <v-form ref="formGoing">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.going.vehicleId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="vehicles"
                      item-text="name"
                      item-value="id"
                      :label="$t('vehicle')"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.going.driverId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="drivers"
                      item-text="name"
                      item-value="id"
                      :label="$t('driver')"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.going.routeId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="routes"
                      item-text="name"
                      item-value="id"
                      :label="$t('route')"
                    />
                  </v-col>
                </v-row>
              </v-form>

              <v-row>
                <v-spacer />
                <v-col cols="2">
                  <v-btn
                    color="primary"
                    block
                    @click="nextStep"
                  >
                    {{ $t('btn_continue') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              
              <v-form ref="formReturning">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.returning.vehicleId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="vehicles"
                      item-text="name"
                      item-value="id"
                      label="Veículo"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.returning.driverId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="drivers"
                      item-text="name"
                      item-value="id"
                      label="Motorista"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="travel.returning.routeId"
                      :no-data-text="$t('no_data_found')"
                      :rules="formRules"
                      :items="routes"
                      item-text="name"
                      item-value="id"
                      label="Rota"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    block
                    @click="currentStep = 1"
                  >
                    {{ $t('btn_back') }}
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="3">
                  <v-btn
                    :loading="isLoadingSave"
                    color="primary"
                    block
                    @click="save"
                  >
                    {{ $t('travels.register.btn_save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-main>
    </BaseCard>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle2.vue";
import BaseCard from '@/Layout/Components/BaseCard.vue';
import CustomDatePicker from '@/components/CustomDatePicker.vue';

import service from '@/service/coreService';

export default {
  name: 'CreateTravelPage',
  components: {
    PageTitle,
    BaseCard,
    CustomDatePicker,
  },
  data() {
    return {
      heading: this.$t('travels.register.page_title'),
      subheading: '',

      formRules: [v => !!v || this.$t('fill_field_warning')],

      currentStep: 1,
      isLoadingSave: false,

      repeatItems: [
        { text: this.$t('travels.register.repeat_options.no_repeat'), value: 'NO_REPEAT' },
        { text: this.$t('travels.register.repeat_options.daily'), value: 'DAILY' },
        { text: this.$t('travels.register.repeat_options.mon_to_fri'), value: 'MON_TO_FRI' },
        { text: this.$t('travels.register.repeat_options.weekly'), value: 'WEEKLY' },
        { text: this.$t('travels.register.repeat_options.custom'), value: 'CUSTOM' },
      ],

      weekDays: [
        { text: 'DOM', value: 'SUNDAY' },
        { text: 'SEG', value: 'MONDAY' },
        { text: 'TER', value: 'TUESDAY' },
        { text: 'QUA', value: 'WEDNESDAY' },
        { text: 'QUI', value: 'THURSDAY' },
        { text: 'SEX', value: 'FRIDAY' },
        { text: 'SAB', value: 'SATURDAY' },
      ],

      travel: {
        daysToRepeat: [],
        repeat: 'NO_REPEAT',
        isActive: false,
        going: {},
        returning: {},
      },
      vehicles: [],
      drivers: [],
      routes: [],

      nextStepButtonPressed: false,
    }
  },
  computed: {
  
  },
  methods: {
    getVehicles() {
      service.vehicles.getVehicles().then(response => {
        this.vehicles = response.data;
      });
    },
    getDrivers() {
      service.drivers.getDrivers().then(response => {
        this.drivers = response.data;
      });
    },
    getRoutes() {
      service.routes.getRoutes().then(response => {
        this.routes = response.data;
      });
    },
    nextStep() {
      if(this.$refs.formGoing.validate()) {
        if(!this.nextStepButtonPressed) {
          this.travel.returning.vehicleId = this.travel.going.vehicleId;
          this.travel.returning.driverId = this.travel.going.driverId;
          this.nextStepButtonPressed = true;
        }
        this.currentStep = 2;
      }
    },
    async save() {
      if(this.$refs.formReturning.validate() && this.$refs.basicInfoForm.validate()) {
        this.isLoadingSave = true;
        if(!!this.travel.id) {
          // if(!!this.travel.travelModel.id) {
          //   const result = await this.$refs.updateTravelDialog.openDialog();
          //   switch(result) {
          //     case 'ONLY_ONE':
          //       break;
          //   };
          //   console.log(result)
          // } else {
          //   this.updateTravel()
          // }
          this.updateTravel();
        } else {
          this.saveTravel();
        }
      }
    },
    saveTravel() {
      service.travels.saveTravel(this.travel).then(() => {
        this.isLoadingSave = false;
        this.$router.push('/travels');
      });
    },
    updateTravel() {
      service.travels.updateTravel(this.travel).then(response => {
        this.isLoadingSave = false;
        this.$router.push('/travels');
      })
    },
    updateRecurrentTravel() {
      service.travels.updateRecurrentTravel(this.travel).then(response => {
        this.isLoadingSave = false;
        this.$router.push('/travels');
      })
    }
  },
  watch: {
  },
  mounted() {
    const travelId = this.$route.params.travelId;
    if(!!travelId) {
      service.travels.getTravelById(travelId).then(response => {
        const travel = response.data;
        if(!!travel.recurrentTravel) {
          travel.repeat = travel.recurrentTravel.travelRepeatType;
          travel.daysToRepeat = travel.recurrentTravel.daysOfWeek;
          travel.until = travel.recurrentTravel.endingDate;
        } else {
          travel.repeat = "NO_REPEAT";
        }

        travel.going.driverId = travel.going.driver.id
        travel.going.vehicleId = travel.going.vehicle.id
        travel.going.routeId = travel.going.route.id

        travel.returning.driverId = travel.returning.driver.id
        travel.returning.vehicleId = travel.returning.vehicle.id
        travel.returning.routeId = travel.returning.route.id

        this.travel = travel;
      })
    }
    this.getVehicles();
    this.getDrivers();
    this.getRoutes();
  }
}
</script>

<style>

</style>