<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>
        {{ $t('routes.create.confirm_delete', { txt: route.name }) }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="default"
          text
          @click="closeDialog"
        >{{ $t('btn_cancel') }}
        </v-btn>
        <v-btn
          color="red"
          text
          @click="confirmDelete"
        >{{ $t('btn_delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteRouteDialog',
  data() {
    return {
      show: false,
      route: {},
    }
  },
  methods: {
    openDialog(route) {
      console.log(route);
      this.route = route;
      this.show = true;
    },
    closeDialog() {
      this.route = {};
      this.show = false;
    },
    confirmDelete() {
      this.$emit('deleteRoute', this.route.id);
      this.closeDialog();
    }
  }
}
</script>

<style>

</style>