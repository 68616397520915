<template>
<div>
  <v-menu
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        v-model="dateFormatted"
        :rules="rules"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :disabled="disabled"
      show-adjacent-months
      locale="pt-br"
      v-model="finalDate"
      :allowed-dates="allowedDates"
      no-title
      @input="dateInput"
    ></v-date-picker>
  </v-menu>
</div>
</template>

<script>
export default {
  name: 'CustomDatePicker',
  props: {
    label: String,
    rules: Array,
    initialDate: String,
    disabled: Boolean,
  },
  data() {
    return {
      menu: false,
      finalDate: null,
    }
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.finalDate);
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    dateInput() {
      this.menu = false;
      this.$emit('inputDate', this.finalDate);
    },
    allowedDates(date) {
      const now = new Date(Date.now());

      const from = now.toISOString().split('T')[0];
      const to = new Date(now.setMonth(now.getMonth() + 6)).toISOString().split('T')[0];
      
      return date >= from && date <= to;
    },
  },
  mounted() {
    const vm = this;
    setTimeout(() => {
      vm.finalDate = vm.initialDate;
    }, 200)
  }
}
</script>

<style>

</style>