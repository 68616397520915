<template>
  <div :class="sidebarbg" class="app-sidebar sidebar-shadow"
    @mouseover="toggleSidebarHover('add','closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove','closed-sidebar-open')">
    <div class="app-header__logo">
      <div class="logo-src" />
      <div class="header__pane ml-auto">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }" @click="toggleBodyClass('closed-sidebar')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-sidebar-content">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <sidebar-menu showOneChild :menu="menu">
        </sidebar-menu>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const canAccessUsers = function() {
  const authorities = JSON.parse(Cookies.get('user')).authorities;
  return authorities.includes('read:user');
}

export default {
  name: 'Sidebar',
  components: {
    SidebarMenu,
    VuePerfectScrollbar
  },
  data() {
    return {
      isOpen: false,
      sidebarActive: false,

      menu: [],

      collapsed: true,

      windowWidth: 0
    };
  },
  props: {
    sidebarbg: String
  },
  methods: {
    getMenuItems() {
      const locale = this.$i18n.messages[this.$i18n.locale];
      this.menu = [
        {
          href: "/travels",
          title: locale.menu.travels,
          icon: {
            element: 'span',
            class: 'lnr lnr-calendar-full'
          }
          // icon: {
          //   element: 'font-awesome-icon',
          //   class: 'ahh',
          //   attributes: {
          //     size: '5x',
          //     icon: ['far', 'calendar'],
          //   }
          // }
        },
        {
          href: "/vehicles",
          title: locale.menu.vehicles,
          icon: {
            element: 'span',
            class: 'lnr lnr-bus'
          }
        },
        {
          href: "/drivers",
          title: locale.menu.drivers,
          icon: {
            element: 'span',
            class: 'lnr lnr-license'
          }
        },
        {
          href: "/routes",
          title: locale.menu.routes,
          icon: {
            element: 'span',
            class: 'lnr lnr-map-marker'
          }
        },
        {
          href: "/passengers",
          title: locale.menu.passengers,
          icon: {
            element: 'span',
            class: 'lnr lnr-briefcase'
          }
        },
        {
          href: "/users",
          disabled: !canAccessUsers(),
          title: locale.menu.users,
          icon: {
            element: 'span',
            class: 'lnr lnr-users'
          },
        },
      ];
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleSidebarHover(add, className) {
      const el = document.body;
      this.sidebarActive = !this.sidebarActive;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth > "992") {
        if (add === "add") {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }
    },
    getWindowWidth() {
      const el = document.body;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "1350") {
        el.classList.add("closed-sidebar", "closed-sidebar-md");
      } else {
        el.classList.remove("closed-sidebar", "closed-sidebar-md");
      }
    },
  },
  watch: {
    '$i18n.locale': function() {
      this.getMenuItems();
      this.$forceUpdate();
    }
  },
  created() {
    this.getMenuItems();
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>
