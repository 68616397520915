<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title>
        Cadastrar novo motorista
      </v-card-title>
      <v-card-text>
        <v-form v-model="isFormValid" ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field 
                  dense
                  :label="$t('drivers.name_label')"
                  v-model="driver.name"
                  type="text"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :label="$t('drivers.email_label')"
                  v-model="driver.email"
                  type="email"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :label="$t('drivers.password_label')"
                  type="password"
                  v-model="driver.password"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="driver.image"
                  dense
                  accept="image/*"
                  :label="$t('drivers.photo_label')"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="default" @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
        <v-btn text color="primary" @click="save">{{ $t('btn_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from '@/service/coreService'
import {util} from '@/util/util';

export default {
  name: 'CreateDriverDialog',
  data() {
    return {
      show: false,
      isFormValid: false,
      formRules: [v => !!v || 'Preencha este campo'],
      driver: {},
    }
  },
  methods: {
    openDialog(driver) {
      this.driver = driver;
      this.show = true;
    },
    closeDialog() {
      this.driver = {};
      this.show = false;
    },
    async save() {
      if(this.$refs.form.validate()) {
        if(!!this.driver.image) {
          var finalImage = await util.toBase64(this.driver.image)
          this.driver.image = finalImage;
        }
        service.drivers.saveDriver(this.driver).then((response) => {
          this.$emit('saveDriver', response.data);
          this.show = false;
        })
      }
    }
  }
}
</script>

<style>

</style>