<template>
<div @click="$emit('itemClicked')" class="list-group-item" style="cursor: pointer">
  <div class="widget-content p-0">
    <div class="widget-content-wrapper">
      <!-- <div class="widget-content-left mr-3">
        <div class="widget-content-left">

        </div>
      </div> -->
      <div class="widget-content-left flex2">
        <div class="widget-heading">{{ route.name }}</div>
        <!-- <div class="widget-subheading">{{ route.email }}</div> -->
      </div>
      <div class="widget-content-right text-center mr-5">
        <div>{{ firstStop.name }}</div>
        <strong>{{ firstStop.arrivalTime }}</strong>
      </div>
      <div class="widget-content-right text-center- mr-5">
        <v-icon
          color="primary"
        >mdi-arrow-right</v-icon>
      </div>
      <div class="widget-content-right text-center mr-5">
        <div>{{ lastStop.name }}</div>
        <strong>{{ lastStop.arrivalTime }}</strong>
      </div>
      <v-spacer />
      <div class="widget-content-right text-center mr-5">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click.stop="duplicate"
            >
              <v-icon small>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('duplicate') }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
  <ConfirmDialog ref="confirmDialog" />
</div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'RouteListItem',
  props: {
    route: Object
  },
  components: { ConfirmDialog },
  computed: {
    firstStop() {
      return this.route.stops[0];
    },
    lastStop() {
      return this.route.stops[this.route.stops.length-1];
    }
  },
  methods: {
    async duplicate() {
      let result = await this.$refs.confirmDialog.openDialog(this.$t('confirm_create_copy', { txt: this.route.name }));
      if(result) {
        this.$emit('duplicate', this.route);
      }
    }
  }
}
</script>

<style>

</style>