<template>
  <v-dialog v-model="show" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        icon
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>
    <v-card v-if="show">
      <v-card-title>{{ $t('travels.delete_travel', { travelName: travel.name }) }}</v-card-title>
      <template v-if="!!travel.travelModel">
        <v-card-text>
          <v-radio-group v-model="deleteOption">
            <v-radio
              :label="`Apenas neste dia (${formatDate(travel.date)})`"
              value="ONLY_THIS"
            ></v-radio>
            <v-radio
              label="Esta e as próximas viagens"
              value="THIS_AND_NEXT"
            ></v-radio>
            <v-radio
              label="Todas as viagens não iniciadas"
              value="ALL"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            @click="show = false"
          >{{ $t('btn_cancel') }}</v-btn>
          <v-btn
            text
            small
            color="primary"
            @click="confirm"
          >{{ $t('btn_confirm') }}</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="show = false"
          >{{ $t('btn_cancel') }}</v-btn>
          <v-btn
            text
            color="primary"
            @click="confirm"
          >{{ $t('btn_confirm') }}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteTravelDialog',
  props: {
    travel: Object,
  },
  data() {
    return {
      show: false,
      deleteOption: 'ONLY_THIS',
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.deleteOption);
      this.show = false
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style>

</style>