import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import MainPage from '../pages/MainPage.vue';
import TravelsPage from '../pages/travels/TravelsPage.vue';
import DriversPage from '@/pages/drivers/DriversPage.vue';
import VehiclesPage from '@/pages/vehicles/VehiclesPage.vue'
import UsersPage from '@/pages/users/UsersPage.vue';
import LoginPage from '@/pages/login/LoginPage.vue';
import CreateTravelPage from '@/pages/travels/CreateTravelPage.vue';
import RoutesPage from '@/pages/routes/RoutesPage.vue';
import CreateRoutePage from '@/pages/routes/CreateRoutePage.vue';
import RegisterPage from '@/pages/login/RegisterPage.vue';
import PassengersPage from '@/pages/passengers/PassengersPage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'userpages' },
    component: LoginPage
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'userpages' },
    component: RegisterPage
  },
  {
    path: '/travels',
    name: 'travels',
    component: TravelsPage
  },
  {
    path: '/travels/create',
    name: 'travelsCreate',
    component: CreateTravelPage
  },
  {
    path: '/routes',
    name: 'routes',
    component: RoutesPage
  },
  {
    path: '/routes/create',
    name: 'routesCreate',
    component: CreateRoutePage
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: DriversPage
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    component: VehiclesPage
  },
  {
    path: '/passengers',
    name: 'passengers',
    component: PassengersPage
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.isLoggedIn;
  if (!isAuthenticated && to.name !== 'login' && to.name !== 'register')
    return next('/login');
  else
    return next();
})

export default router
