<template>
  <div>
    <div class="h-100 bg-premium-dark">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="8" class="mx-auto app-login-box">
          <div class="app-logo-inverse mx-auto mb-3" />
          <div class="modal-dialog w-100">
            <div class="modal-content">
              <v-form ref="form" v-model="formIsValid">
                <div class="modal-body">
                  <h5 class="modal-title">
                    <h4 class="mt-2">
                      <div>Bem vindo</div>
                    </h4>
                  </h5>
                  <div class="divider" />
                  <v-text-field 
                    outlined 
                    dense
                    :rules="formRules"
                    placeholder="Nome da sua empresa"
                    v-model="form.companyName"
                  ></v-text-field>
                  <v-text-field 
                    outlined 
                    dense
                    :rules="formRules"
                    placeholder="E-mail da sua empresa"
                    v-model="form.email"
                    type="email"
                  ></v-text-field>
                  <v-text-field 
                    outlined 
                    dense
                    :rules="formRules"
                    placeholder="CNPJ"
                    v-model="form.cnpj"
                  ></v-text-field>
                  <v-text-field 
                    outlined 
                    dense
                    :rules="formRules"
                    placeholder="Endereço"
                    v-model="form.address"
                  ></v-text-field>
                  <v-text-field 
                    outlined 
                    dense
                    :rules="formRules"
                    placeholder="Telefone"
                    v-model="form.phoneNumber"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-text-field 
                        outlined 
                        dense
                        :rules="formRules"
                        placeholder="Senha"
                        v-model="form.password"
                        type="password"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field 
                        outlined 
                        dense
                        :rules="formRules"
                        placeholder="Repetir senha"
                        v-model="confirmPassword"
                        type="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <b-form-checkbox name="check" id="exampleCheck">
                    Accept our
                    <a href="javascript:void(0);">Terms and Conditions</a>.
                  </b-form-checkbox> -->
                  <div class="divider" />
                  <h6 class="mb-0">
                    Já tem uma conta?
                    <router-link to="/login" class="text-primary">Entrar</router-link>
                    |
                    <a href="javascript:void(0);" class="text-primary"> Esqueci a senha </a>
                  </h6>
                </div>
                <div class="modal-footer d-block text-center">
                  <b-button @click="register" color="primary" class="btn-wide btn-pill btn-shadow btn-hover-shine" style="color: white" size="lg">Criar Conta</b-button>
                </div>
              </v-form>
            </div>
          </div>
          <div class="text-center text-white opacity-8 mt-3">Copyright &copy; 2023 EasyTravel, CodeScript Tecnologia</div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';

const url = process.env.VUE_APP_CORE_API;

export default {
  name: 'RegisterPage',
  data() {
    return {
      formIsValid: false,
      formRules: [v => !!v || 'Preencha este campo'],
      form: {},
      confirmPassword: '',
    }
  },
  methods: {
    register() {
      if(this.$refs.form.validate() && this.confirmPassword === this.form.password) {
        Axios.post(url + '/register/company', this.form).then(() => {
          this.$router.push('/login');
        })
      }
    }
  }
}
</script>
