<template>
  <v-expansion-panel class="list-group-item">
    <ConfirmDialog ref="deleteDialog" />
    <v-expansion-panel-header class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left mr-3">
          <div class="widget-content-left">
            <!-- <v-img width="42" class="rounded-circle" :src="'data:image/jpeg;base64,'+image" alt /> -->
            <v-avatar color="grey">
              <v-img :src="'data:image/jpeg;base64,'+image" alt/>
            </v-avatar>
          </div>
        </div>
        <div class="widget-content-left flex2">
          <div class="widget-heading">{{ user.email }}</div>
        </div>
        <v-spacer />
        <div class="widget-content-right text-center mr-5">
          <div :class="`ml-auto badge badge-${roles[user.roleGroup].color}`">{{ roles[user.roleGroup].label }}</div>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import service from '@/service/coreService';
import { mapState } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  components: { ConfirmDialog },
  name: 'UserListItem',
  props: {
    user: Object
  },
  data() {
    return {
      image: null,
      roles: {
        ADMIN: { color: 'danger', label: this.$t('users.role_label.admin').toUpperCase() },
        MANAGER: { color: 'primary', label: this.$t('users.role_label.manager').toUpperCase() },
        MEMBER: { color: 'success', label: this.$t('users.role_label.member').toUpperCase() },
      }
    }
  },
  computed: {
    ...mapState({
      authorities: state => state.auth.user.authorities,
    }),
  },
  methods: {
  },
  mounted() {
    service.users.getImage(this.user.id).then(response => {
      this.image = response.data;
    });
  }
}
</script>

<style>

</style>