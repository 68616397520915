<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" @onCreateNew="$router.push('travels/create')"></PageTitle>
    <TravelDetailsDialog ref="travelDetailsDialog" @updateTravels="getTravels" />
    <v-sheet class="mb-6">
      <v-toolbar flat>
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="focus = ''"
        >
          {{ $t('today_btn') }}
        </v-btn>
        <v-btn
          icon
          color="grey darken-2"
          @click="prev"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span v-if="$refs.calendar">
          {{ calendarTitle }}
        </span>
        <v-btn
          icon
          color="grey darken-2"
          @click="next"
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear v-show="loadingTravels" indeterminate></v-progress-linear>
      <v-calendar
        locale="pt-br"
        @click:event="eventClicked"
        :event-color="travelColor"
        v-model="focus"
        ref="calendar"
        type="week"
        :events="travels"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle2.vue";
import TravelDetailsDialog from '@/pages/travels/TravelDetailsDialog.vue';

import service from '@/service/coreService';

export default {
  name: 'TravelsPage',
  components: {
    PageTitle,
    TravelDetailsDialog,
  },
  data() {
    return {
      heading: this.$t('menu.travels'),
      subheading: '',
      travels: [],
      focus: '',
      loadingTravels: false,
    }
  },
  computed: {
    calendarTitle() {
      const startDate = this.formatDate(this.$refs.calendar.lastStart.date);
      const endDate = this.formatDate(this.$refs.calendar.lastEnd.date);

      this.getTravels();

      return `${startDate} - ${endDate}`;
    },
  },
  methods: {
    getTravels() {
      this.loadingTravels = true;
      const starDate = this.$refs.calendar.lastStart.date;
      const endDate = this.$refs.calendar.lastEnd.date;

      service.travels.getTravels(starDate, endDate).then(response => {
        const travels = response.data;
        travels.forEach(travel => {
          this.formatTravel(travel);
        })
        this.travels = travels;
      })
      .finally(() => {
        this.loadingTravels = false;
      });
    },
    formatTravel(travel) {
      const goingStartTime = travel.going.route.stops[0].arrivalTime;
      const returningEndTime = travel.returning.route.stops.pop().arrivalTime;

      travel.start = `${travel.date} ${goingStartTime}`;
      travel.end = `${travel.date} ${returningEndTime}`;
    },
    eventClicked(value) {
      this.$refs.travelDetailsDialog.openDialog(value.event.id);
    },
    travelColor(travel) {
      switch(travel.travelStatus) {
        case 'STARTED':
          return 'green';
        case 'FINISHED':
          return 'grey';
        case 'CANCELLED':
          return 'red';
        default:
          return 'primary';
      }
    },
    prev () {
      this.$refs.calendar.prev();
    },
    next () {
      this.$refs.calendar.next();
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime('05:45');
    this.calendarTitle;
  }
}
</script>

<style>

</style>