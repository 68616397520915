<template>
  <v-dialog v-model="show" v-if="show" max-width="1000" scrollable>
    <v-card height="650">
      <v-progress-linear v-if="loadingDetails" indeterminate></v-progress-linear>
      <template v-else>
        <v-app-bar
          flat
          color="white"
        >
          <v-toolbar-title class="text-h5 pl-0">
            {{ travel.name }}
          </v-toolbar-title>
          <div :class="`badge badge-${travelStatusLabel[travel.travelStatus].color} ml-2`">
            {{ travelStatusLabel[travel.travelStatus].text }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="goToEditPage"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <DeleteTravelDialog :travel="travel" @confirm="deleteTravel" />
        </v-app-bar>
        <v-card-text>
          <!-- <VuePerfectScrollBar class="scrollbar-container"> -->
            <v-row>
              <v-col>
                <p class="title">{{ $t('travels.outward') }}</p>
                <v-row class="mb-4">
                  <v-col>
                    <CustomTile 
                      :title="$t('vehicle')" 
                      :subtitle="travel.going.vehicle.name"
                      :imageFromEntity="'vehicles'"
                      :imageName="travel.going.vehicle.id"
                    />
                  </v-col>
                  <v-col>
                    <CustomTile 
                      :title="$t('driver')" 
                      :subtitle="travel.going.driver.name" 
                      :imageFromEntity="'drivers'"
                      :imageName="travel.going.driver.id"
                    />
                  </v-col>
                </v-row>
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column mb-4">
                  <div 
                    class="vertical-timeline-element" 
                    v-for="(stop, index) in travel.going.route.stops" 
                    :key="index"
                  >
                    <div>
                      <span 
                        class="vertical-timeline-element-icon bounce-in"
                        style="cursor: pointer"
                      >
                        <i :class="`badge badge-dot badge-dot-xl badge-${stopTypeColor[stop.stopType]}`"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title text-truncate" style="max-width: 300px">{{ stop.name }}</h4>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <p 
                              v-bind="attrs"
                              v-on="on"
                              class="text-truncate" 
                              style="max-width: 300px"
                            >
                              {{ stop.stopAddress }}
                            </p>
                          </template>
                          <span>{{ stop.stopAddress }}</span>
                        </v-tooltip>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <button 
                              v-on="on"
                              v-bind="attrs"
                              style="color: white"
                              class="badge badge-secondary"
                            >
                              {{ $t('menu.passengers') }}: {{ getNumberOfPassengers(stop.id) }}
                            </button>
                          </template>
                          <div class="list-group" v-if="!!stopPassengers[stop.id]">
                            <div 
                              class="list-group-item"  
                                v-for="(passenger, index) in stopPassengers[stop.id]"
                                :key="index"
                            >
                              <CustomTile
                                :title="passenger.name" 
                                :subtitle="passenger.email" 
                                :imageFromEntity="'passengers'"
                                :imageName="passenger.id"
                              />
                            </div>
                          </div>
                        </v-menu>
                        <span class="vertical-timeline-element-date">{{ stop.arrivalTime }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <LMap
                  ref="goingMap"
                  style="width: 100%; height: 300px"
                  :zoom="zoom"
                  :center="center"
                  :bounds="goingBounds"
                  :options="mapOptions"
                  @ready="onMapReady"
                >
                  <LMarker 
                    v-for="stop in travel.going.route.stops" :key="stop.id"
                    :lat-lng="[stop.latitude, stop.longitude]"
                  ></LMarker>
                  <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
                </LMap> -->
              </v-col>
              <v-divider vertical />
              <v-col>
                <p class="title">{{ $t('travels.return') }}</p>
                <v-row class="mb-4">
                  <v-col>
                    <CustomTile 
                      :title="$t('vehicle')" 
                      :subtitle="travel.returning.vehicle.name" 
                      :imageFromEntity="'vehicles'"
                      :imageName="travel.returning.vehicle.id"
                    />
                  </v-col>
                  <v-col>
                    <CustomTile
                      :title="$t('driver')" 
                      :subtitle="travel.returning.driver.name"
                      :imageFromEntity="'drivers'"
                      :imageName="travel.returning.driver.id"
                    />
                  </v-col>
                </v-row>
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column mb-4">
                  <div 
                    class="vertical-timeline-element" 
                    v-for="(stop, index) in travel.returning.route.stops" 
                    :key="index"
                  >
                    <div>
                      <span class="vertical-timeline-element-icon bounce-in">
                        <i :class="`badge badge-dot badge-dot-xl badge-${stopTypeColor[stop.stopType]}`"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title text-truncate" style="max-width: 300px">{{ stop.name }}</h4>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <p 
                              v-bind="attrs"
                              v-on="on"
                              class="text-truncate" 
                              style="max-width: 300px"
                            >{{ stop.stopAddress }}</p>
                          </template>
                          <span>{{ stop.stopAddress }}</span>
                        </v-tooltip>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <button 
                              v-on="on"
                              v-bind="attrs"
                              style="color: white"
                              class="badge badge-secondary"
                            >
                              {{ $t('menu.passengers') }}: {{ getNumberOfPassengers(stop.id) }}
                            </button>
                          </template>
                          <div class="list-group" v-if="!!stopPassengers[stop.id]">
                            <div 
                              class="list-group-item"  
                                v-for="(passenger, index) in stopPassengers[stop.id]"
                                :key="index"
                            >
                              <CustomTile
                                :title="passenger.name" 
                                :subtitle="passenger.email" 
                                :imageFromEntity="'passengers'"
                                :imageName="passenger.id"
                              />
                            </div>
                          </div>
                        </v-menu>
                        <span class="vertical-timeline-element-date">{{ stop.arrivalTime }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          <!-- </VuePerfectScrollBar> -->
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTile from '@/components/CustomTile.vue';
import DeleteTravelDialog from './DeleteTravelDialog.vue';
import VuePerfectScrollBar from 'vue-perfect-scrollbar';

import service from '@/service/coreService';

export default {
  name: 'TravelDetailsDialog',
  components: {
    CustomTile,
    DeleteTravelDialog,
    VuePerfectScrollBar,
  },
  data() {
    return {
      show: false,
      travel: {},
      stopPassengers: {},

      travelStatusLabel: {
        INITIAL: { color: 'primary', text: this.$t('travels.status_label.initial') },
        STARTED: { color: 'success', text: this.$t('travels.status_label.started') },
        FINISHED: { color: 'secondary', text: this.$t('travels.status_label.finished') },
        CANCELLED: { color: 'danger', text: this.$t('travels.status_label.cancelled') },
      },

      stopTypeColor: {
        BOARDING: 'success',
        LANDING: 'danger'
      },

      loadingDetails: false,
    }
  },
  methods: {
    async openDialog(travelId) {
      this.loadingDetails = true;
      this.show = true;
      await this.getTravel(travelId);
      await this.getPassengers(travelId);
      this.loadingDetails = false;
    },
    closeDialog() {
      this.show = false;
    },
    async getTravel(travelId) {
      return service.travels.getTravelById(travelId).then(response => {
        this.travel = response.data;
        // this.updateBounds();
      });
    },
    goToEditPage() {
      this.$router.push({ name: 'travelsCreate', params: { travelId: this.travel.id }});
    },

    getPassengers(travelId) {
      this.stopPassengers = {};
      return service.travels.getPassengersByTravelId(travelId).then(response => {
        const passengers = response.data;
        passengers.forEach(p => {
          this.stopPassengers[p.goingBoardingStop.id] = this.stopPassengers[p.goingBoardingStop.id] || [];
          this.stopPassengers[p.goingLandingStop.id] = this.stopPassengers[p.goingLandingStop.id] || [];
          this.stopPassengers[p.returningBoardingStop.id] = this.stopPassengers[p.returningBoardingStop.id] || [];
          this.stopPassengers[p.returningLandingStop.id] = this.stopPassengers[p.returningLandingStop.id] || [];

          this.stopPassengers[p.goingBoardingStop.id].push(p.passenger);
          this.stopPassengers[p.goingLandingStop.id].push(p.passenger);
          this.stopPassengers[p.returningBoardingStop.id].push(p.passenger);
          this.stopPassengers[p.returningLandingStop.id].push(p.passenger);
        });
      })
    },
    getNumberOfPassengers(stopId) {
      if(!this.stopPassengers[stopId]) return 0;
      return this.stopPassengers[stopId].length;
    },
    deleteTravel(deleteOption) {
      const vm = this;
      const success = function() {
        vm.closeDialog();
        vm.$emit('updateTravels');
      }

      if(deleteOption === 'ONLY_THIS') {
        service.travels.deleteTravel(this.travel.id).then(success)
      } else if(deleteOption === 'THIS_AND_NEXT') {
        const travelModelId = this.travel.travelModel.id;
        const fromDate = this.travel.date;
        service.travels.deleteRecurrentTravels(travelModelId, fromDate).then(success)
      } else {
        service.travels.deleteRecurrentTravels(this.travel.travelModel.id).then(success);
      }
    }
    // updateBounds() {
    //   const goingBounds = latLngBounds(this.travel.going.route.stops.map(stop => {
    //     return { lat: stop.latitude, lng: stop.longitude };
    //   }))
    //   const returningBounds = latLngBounds(this.travel.returning.route.stops.map(stop => {
    //     return { lat: stop.latitude, lng: stop.longitude };
    //   }))
    //   this.goingBounds = goingBounds;
    //   this.returningBounds = returningBounds;
    // },
    // onMapReady() {
    //   this.$refs.goingMap.mapObject._onResize();
    //   this.$refs.returningMap.mapObject._onResize();
    //   this.updateBounds();
    // }
    // async getDriverImage(driverId) {
    //   let image = await service.drivers.getImage(driverId);
    //   return 'data:image/jpeg;base64,'+image
    // },
    // async getVehicleImage(vehicleId) {
    //   let image = await service.vehicles.getImage(vehicleId);
    //   return 'data:image/jpeg;base64,'+image
    // }
  },
}
</script>

<style>

</style>