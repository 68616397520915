<template>
  <div>
    <div class="h-100 bg-easy-travel bg-animation">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="8" class="mx-auto app-login-box">

          <div class="modal-dialog w-100 mx-auto">
            <v-form @submit="login" ref="form" v-model="formIsValid" class="modal-content">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                <img class="mb-2" width="350" src="@/assets/images/logo.png" alt="">
                  <h4 class="mt-2">
                    <span>{{ $t('login.enter_account_below') }}</span>
                  </h4>
                </div>
                <v-text-field 
                  :label="$t('login.email')"
                  color="#8162C2"
                  class="mb-2"
                  v-model="form.username"
                  :rules="formRules"
                  type="email"
                  dense 
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
                <v-text-field 
                  :label="$t('login.password')"
                  color="#8162C2"
                  v-model="form.password"
                  type="password"
                  :rules="formRules"
                  dense 
                  prepend-inner-icon="mdi-lock"
                ></v-text-field>
                <v-btn 
                  block 
                  type="submit" 
                  color="#8160C1" 
                  dark 
                  elevation="0"
                  :loading="loadingLogin"
                >
                  {{ $t('login.login') }}
                </v-btn>

                <!-- <b-form-group id="exampleInputGroup1" label-for="exampleInput1">
                  <b-form-input 
                    v-model="form.username"
                    id="exampleInput1"
                    type="email" 
                    required 
                    placeholder="Seu e-mail"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="exampleInputGroup2" label-for="exampleInput2">
                  <b-form-input 
                    v-model="form.password"
                    id="exampleInput2"  
                    type="password" 
                    required 
                    placeholder="Senha"
                  ></b-form-input>
                </b-form-group> -->
                <div class="divider" />
                <h6 class="mb-4">
                  {{ $t('login.dont_have_account') }}
                  <router-link to="/register" class="text-primary">{{ $t('login.get_in_touch') }}</router-link>
                </h6>
                <v-alert v-model="showAlert" outlined type="error" dismissible text>
                  {{ $t('login.invalid_login') }}
                </v-alert>
              </div>
            </v-form>
          </div>
          <div class="text-center text-white opacity-8 mt-3">Copyright &copy; 2023 EasyTravel, CodeScript Tecnologia</div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      formIsValid: false,
      formRules: [v => !!v || 'Preencha este campo'],
      loadingLogin: false,
      form: {
        username: 'prime@gmail.com',
        password: 'teste123',
      },
      showAlert: false,
    }
  },
  methods: {
    login() {
      if(this.$refs.form.validate()) {
        this.loadingLogin = true;
        this.$store.dispatch('login', this.form).then(() => {
          this.$router.push('/travels');
        })
        .catch(error => {
          this.showAlert = false;
          if(error.response.status === 401) {
            this.showAlert = true;
          }
        })
        .finally(() => {
          this.loadingLogin = false;
        });
      }
    }
  },
}
</script>

<style>
.text-green input {
  color: green !important;
}
</style>
