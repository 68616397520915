<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div class="logo-src" />
    <div class="app-header__content">
      <div class="app-header-left">
        <!-- <SearchBox /> -->
        <!-- <MegaMenu /> -->
      </div>
      <div class="app-header-right">
        <!-- <HeaderDots /> -->
        <!-- <UserArea /> -->
        <div class="d-flex text-right">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ company.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn tile icon @click="logout">
                    <v-icon v-on="on" v-bind="attrs">mdi-logout</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('logout')}}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div class="d-flex text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
              >
                <v-icon small>mdi-web</v-icon>
                {{ $i18n.locale }}
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(loc, index) in avlbLocales"
                :key="index"
                link
                @click="setLocale(loc)"
              >
                <v-list-item-title>{{ loc }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <b-button class="btn-icon btn-icon-only" variant="primary" size="sm"
          v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
          <div class="btn-icon-wrapper">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
import SearchBox from "./Header/SearchBox";
import MegaMenu from "./Header/MegaMenu";
import HeaderDots from "./Header/HeaderDots";
import UserArea from "./Header/HeaderUserArea";
import service from '@/service/coreService';
import { mapState } from 'vuex';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    SearchBox,
    MegaMenu,
    HeaderDots,
    UserArea,
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    headerbg: String
  },
  data() {
    return {
      isOpen: false,
      isOpenMobileMenu: false,
      company: {},
      avlbLocales: this.$i18n.availableLocales,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user.user
    })
  },
  methods: {
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    logout() {
      this.$store.commit('LOGOUT')
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    setLocale(locale) {
      window.localStorage.setItem('locale', locale);
      window.location.reload();
    }
  },
  mounted(){
    service.company.getCompanyById(this.user.companyId).then(response => {
      this.company = response.data;
    })
  }
};
</script>

<style scoped>
.menu-item {
  margin: -10px;
}
</style>
