<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" :createNewButton="false" />
    <CreateStopDialog ref="createStopDialog" @saveStop="addStop" @updateStop="updateStop"/>
    <DeleteRouteDialog ref="deleteRouteDialog" @deleteRoute="deleteRoute" />
    <div class="card main-card mb-6">
      <v-container>
        <v-form ref="form">
          <v-col cols="4">
            <v-text-field 
              :label="$t('routes.create.id_label')"
              :rules="formRules"
              v-model="route.name"
            ></v-text-field>
          </v-col>
        </v-form>
        <v-row>
          <v-col cols="4">
            <div class="scroll-area">
              <VuePerfectScrollbar class="scrollbar-container">
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  <template v-if="route.stops.length > 0">
                    <div 
                      class="vertical-timeline-element" 
                      v-for="(stop, index) in route.stops" 
                      :key="index"
                    >
                      <div>
                        <span 
                          class="vertical-timeline-element-icon bounce-in"
                          style="cursor: pointer"
                          @click="changeMapCenter({ lat: stop.latitude, lng: stop.longitude })"
                        >
                          <i :class="`badge badge-dot badge-dot-xl badge-${stopTypeLabel[stop.stopType].style}`"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">{{ stop.name }}</h4>
                          <p>
                            {{ stop.stopAddress }}
                          </p>
                          <div :class="`badge badge-${stopTypeLabel[stop.stopType].style}`">
                            {{stopTypeLabel[stop.stopType].label}}
                          </div>
                          <button 
                            class="badge badge-secondary ml-2"
                            @click="openStopDialog(stop)"
                          >
                            <v-icon x-small color="white" >mdi-pencil </v-icon>
                          </button>
                          <button 
                            class="badge badge-secondary ml-2"
                            @click="route.stops.splice(index, 1)"
                          >
                            <v-icon x-small color="white">mdi-close</v-icon>
                          </button>
                          <span class="vertical-timeline-element-date">{{ stop.arrivalTime }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="vertical-timeline-element">
                    <div>
                      <span class="vertical-timeline-element-icon bounce-in">
                        <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <button 
                          class="btn btn-primary btn-icon btn-sm"
                          @click="openStopDialog({})"
                        >
                          <v-icon left small color="white">mdi-plus</v-icon>
                          {{ $t('routes.create.btn_add_stop') }}
                        </button>
                        <span class="vertical-timeline-element-date">00 : 00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </VuePerfectScrollbar>
            </div>
          </v-col>
          <v-col cols="8">
            <GmapMap 
              ref="gmap"
              style="height: 100%; width: 100%"
              :center="center" 
              :zoom="zoom"
            >
              <GmapMarker
                :key="index"
                v-for="(stop, index) in route.stops"
                :position="{ lat: stop.latitude, lng: stop.longitude }"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" v-if="!!route.id">
            <button 
              class="btn btn-outline-danger btn-block"
              @click="$refs.deleteRouteDialog.openDialog(route)"
            >
              <span v-if="!loadingDelete">
                {{ $t('routes.create.btn_delete') }}
              </span>
              <v-progress-circular 
                v-else 
                size="20" 
                width="1.5" 
                color="danger" 
                indeterminate 
              ></v-progress-circular>
            </button>
          </v-col>
          <v-spacer/>
          <v-col cols="2">
            <button 
              class="btn btn-outline-primary btn-block"
              @click="$router.push('/routes')"
            >{{ $t('btn_cancel') }}</button>
          </v-col>
          <v-col cols="2">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingSave"
              @click="save"
            >
              <span v-if="!loadingSave">
                {{ $t('btn_save') }}
              </span>
              <v-progress-circular size="20" width="2" color="white" indeterminate v-else ></v-progress-circular>
            </button>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert v-model="showError" outlined type="red" dismissible text>
              {{errorMessage}}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/Layout/Components/PageTitle2.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import CreateStopDialog from './CreateStopDialog.vue';
import DeleteRouteDialog from './DeleteRouteDialog.vue';

import { gmapApi } from 'vue2-google-maps';

import service from '@/service/coreService';

export default {
  name: 'CreateRoutePage',
  components: {
    PageTitle,
    VuePerfectScrollbar,
    CreateStopDialog,
    DeleteRouteDialog,
  },
  data() {
    return {
      heading: this.$t('routes.create.page_title'),
      subheading: '',
      formRules: [v => !!v || this.$t('fill_field_warning')],
      loadingSave: false,
      loadingDelete: false,
      errorMessage: '',
      zoom: 7,
      center: {lat: -27, lng: -52},
      stopTypeLabel: {
        BOARDING: {
          label: this.$t('routes.create.stop.boarding_type'),
          style: 'success'
        },
        LANDING: {
          label: this.$t('routes.create.stop.landing_type'),
          style: 'danger'
        },
      },
      route: {
        name: '',
        stops: []
      },
      showError: false
    }
  },
  methods: {
    openStopDialog(stop) {
      this.$refs.createStopDialog.openDialog(stop);
    },
    addStop(newStop) {
      this.route.stops.push(newStop);
      this.$refs.createStopDialog.closeDialog();
      this.$refs.createStopDialog.loadingSave = false;
      this.sortStops();
    },
    updateStop(stop) {
      this.route.stops.splice(this.route.stops.findIndex(s => s.id === stop.id), 1, stop);
      this.$refs.createStopDialog.closeDialog();
      this.$refs.createStopDialog.loadingSave = false;
      this.sortStops();
    },
    save() {
      this.loadingSave = true;
      if(!!this.route.id) {
        this.updateRoute();
      } else {
        this.saveRoute();
      }
    },
    saveRoute() {
      if(this.validateForm()) {
        service.routes.saveRoute(this.route).then(() => {
          this.$router.push('/routes');
        }).finally(() => {
          this.loadingSave = false;
        });
      }
    },
    updateRoute() {
      if(this.validateForm()) {
        service.routes.updateRoute(this.route).then(() => {
          this.$router.push('/routes');
        }).finally(() => {
          this.loadingSave = false;
        });
      }
    },
    deleteRoute() {
      this.loadingDelete = true;
      service.routes.deleteRoute(this.route.id)
      .then(() => {
        this.$router.push('/routes');
      })
      .catch(error => {
        if(!!error.response.data.data && !!error.response.data.data.routeId) {
          this.errorMessage = this.$t('routes.create.delete_error_msg');
          this.showError = true;
        }
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    },
    sortStops() {
      this.route.stops.sort((a, b) => {
        const timeA = a.arrivalTime;
        const timeB = b.arrivalTime;
        if (timeA < timeB) {
          return -1;
        }
        if (timeA > timeB) {
          return 1;
        }
        return 0;
      });
    },
    validateForm() {
      if(this.$refs.form.validate()) {
        let stops = this.route.stops;

        const hasBoardingStop = stops.find(stop => stop.stopType === 'BOARDING');
        const hasLandingStop = stops.find(stop => stop.stopType === 'LANDING');

        const result = !!hasLandingStop && !!hasBoardingStop;
        if(!result) {
          this.errorMessage = this.$t('routes.create.create_error_msg');
          this.showError = true;
        }
        return result;
      }
      return false;
    },
    changeMapCenter(position) {
      this.center = position;
      this.zoom = 15;
    },
    setMapBounds() {
      const gmap = this.$refs.gmap;
      const api = gmapApi().maps;

      const latLngBounds = new api.LatLngBounds();

      this.route.stops.forEach(stop => {
        const latLng = new api.LatLng(stop.latitude, stop.longitude);
        latLngBounds.extend(latLng);
      });

      gmap.fitBounds(latLngBounds);
    }
  },
  mounted() {
    const routeId = this.$route.params.routeId;
    if(!!routeId) {
      service.routes.getRouteById(routeId).then(response => {
        this.route = response.data;
        this.setMapBounds();
        this.sortStops();
      })
    }
  }
}
</script>

<style>

</style>