<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title>
        {{ $t('users.create_dialog_title') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="isFormValid" ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  :label="$t('users.email_label')"
                  v-model="user.email"
                  type="email"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :label="$t('users.password_label')"
                  type="password"
                  v-model="user.password"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="roles"
                  dense
                  :label="$t('users.type_label')"
                  v-model="user.role"
                  :rules="formRules"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="user.image"
                  dense
                  accept="image/*"
                  :label="$t('users.photo_label')"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="default" @click="closeDialog">{{ $t('btn_cancel') }}</v-btn>
        <v-btn text color="primary" @click="save">{{ $t('btn_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from '@/service/coreService'
import {util} from '@/util/util';

export default {
  name: 'CreateUserDialog',
  data() {
    return {
      show: false,
      isFormValid: false,
      formRules: [v => !!v || this.$t('fill_field_warning')],
      user: {},
      roles: [
        { text: this.$t('users.role_label.admin'), value: 'ADMIN' },
        { text: this.$t('users.role_label.manager'), value: 'MANAGER' },
        { text: this.$t('users.role_label.member'), value: 'MEMBER' },
      ]
    }
  },
  watch: {
    show(value) {
      if(value === false) {
        this.user = {};
        this.show = false;
        this.$refs.form.resetValidation();
      }
    }
  },
  methods: {
    openDialog(user) {
      this.user = user;
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    async save() {
      if(this.$refs.form.validate()) {
        if(!!this.user.image) {
          var finalImage = await util.toBase64(this.user.image)
          this.user.image = finalImage;
        }
        service.users.saveUser(this.user).then((response) => {
          this.$emit('updateUsers');
          this.show = false;
        })
      }
    }
  }
}
</script>

<style>

</style>