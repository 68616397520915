<template>
  <v-dialog v-model="show" max-width="500">
    <v-card>
      <v-card-title>
        {{ message }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="cancel"
        >
          {{ $t('btn_cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm"
        >
          {{ $t('btn_confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      show: false,
      resolve: null,
      message: '',
    }
  },
  methods: {
    openDialog(message) {
      this.show = true;
      this.message = message;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    confirm() {
      this.resolve(true);
      this.show = false;
    },
    cancel() {
      this.resolve(false);
      this.show = false;
    },
  }
}
</script>

<style>

</style>