<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" @onCreateNew="openDriverDialog({})"></PageTitle>
    <CreateDriverDialog ref="driverDialog" @saveDriver="getDrivers" />
    <div class="main-card mb-3 card">
      <div class="card-body">
        <div class="text-center">
          <v-progress-circular v-show="loadingDrivers" indeterminate></v-progress-circular>
        </div>
        <v-expansion-panels class="list-group" flat accordion>
          <DriverListItem 
            v-for="driver in drivers"
            :key="driver.id"
            :driver="driver" 
          />
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/Layout/Components/PageTitle2.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBusAlt } from '@fortawesome/free-solid-svg-icons';
import CreateDriverDialog from './CreateDriverDialog.vue';

import service from '@/service/coreService';
import DriverListItem from './DriverListItem.vue';

library.add(faBusAlt)

export default {
  name: 'DriversPage',
  components: {
    PageTitle,
    CreateDriverDialog,
    DriverListItem,
  },
  data() {
    return {
      heading: this.$t('menu.drivers'),
      subheading: '',
      drivers: [],
      loadingDrivers: false,
    }
  },
  methods: {
    getDrivers() {
      this.loadingDrivers = true;
      service.drivers.getDrivers().then(response => {
        this.drivers = response.data;
      })
      .finally(() => {
        this.loadingDrivers = false;
      });
    },
    openDriverDialog(driver) {
      this.$refs.driverDialog.openDialog(driver);
    },
  },
  mounted() {
    this.getDrivers();
  }
}
</script>
