<template>
<v-card>
  <DeleteImageDialog ref="deleteImageDialog" @deleteImage="deleteImage" />
  <v-img 
    :src="'data:image/jpeg;base64,'+image" 
    height="200"
    :gradient="showImageOptions ? imagesGradient : null"
    @mouseenter="showImageOptions = true"
    @mouseleave="showImageOptions = false"
  >
    <v-app-bar
      v-show="showImageOptions"
      flat
      color="rgba(0, 0, 0, 0)"
    >
      <input
        ref="fileInput"
        v-show="false"
        type="file"
        accept="image/*"
        @change="onFileChange" 
      />
      <v-spacer></v-spacer>
      <v-btn
        v-show="!!image"
        color="white"
        icon
        @click="$refs.deleteImageDialog.openDialog(vehicle.name)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn
        color="white"
        icon
        @click="$refs.fileInput.click()"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-app-bar>
  </v-img>
  <v-card-title>{{ vehicle.name }}</v-card-title>
  
  <v-card-text>
    <v-row>
      <v-col>
        <div>
          {{ $t('vehicles.seats') }}
        </div>
        <strong class="bigger-text">{{vehicle.seats}}</strong>
      </v-col>
      <v-col>
        <div>
          {{ $t('vehicles.plate') }}
        </div>
        <strong class="bigger-text">{{vehicle.plate}}</strong>
      </v-col>
      <v-col>
        <div>
          {{ $t('vehicles.travels') }}
        </div>
        <strong class="bigger-text">{{vehicle.numTravels}}</strong>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions>
    <v-btn
      color="red"
      icon
      @click="$emit('openDeleteVehicleDialog', vehicle)"
    >
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary" outlined @click="$emit('openVehicleDialog', vehicle, image)">
      <v-icon small right
      >mdi-pencil-outline</v-icon>
      {{ $t('btn_edit') }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import service from '@/service/coreService';
import {util} from '@/util/util';
import DeleteImageDialog from './DeleteImageDialog.vue';

export default {
  components: { DeleteImageDialog },
  name: 'VehicleCard',
  props: {
    vehicle: Object,
  },
  data() {
    return {
      image: null,
      showImageOptions: false,
      imagesGradient: 'to top right, rgba(0,0,0,0), rgba(0,0,0,.5)',
    }
  },
  methods: {
    getImage() {
      service.vehicles.getImage(this.vehicle.id).then(response => {
        this.image = response.data;
      })
    },
    saveImage(image) {
      service.vehicles.saveImage(this.vehicle.id, image).then(() => {
        this.image = image;
      }) 
    },
    deleteImage() {
      service.vehicles.deleteImage(this.vehicle.id).then(() => {
        this.image = null;
      });
    },
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if(!!files[0]){
        var finalImage = await util.toBase64(files[0]);
        this.saveImage(finalImage);
      }
    }
  },
  mounted() {
    this.getImage();
  }
}
</script>

<style scoped>
.bigger-text {
  font-size: 16px;
}
</style>
