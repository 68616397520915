<template>
  <div class="d-flex">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading">{{ company.name }}</div>
          <b-dropdown 
            toggle-class="p-0 mr-2" 
            menu-class="dropdown-menu-md"

            variant="default" 
            right
          >
            <span class="widget-subheading" slot="button-content">Conta</span>
            <b-dropdown-item>
              teste
            </b-dropdown-item>
            <!-- <div class="dropdown-menu-header">
              <div class="dropdown-menu-header-inner bg-info">
                <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                <div class="menu-header-content text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <img width="42" class="rounded-circle" :src="'data:image/jpeg;base64,'+image" alt />
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{ user.email }}</div>
                        <div class="widget-subheading opacity-8">{{ company.name }}</div>
                      </div>
                      <div class="widget-content-right mr-2">
                        <button 
                          class="btn-pill btn-shadow btn-shine btn btn-focus"
                          @click="logout"
                        >Sair</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="grid-menu grid-menu-2col overflow-hidden">
              <div class="no-gutters row">
                <div class="col-sm-6">
                  <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-success">
                    <i class="pe-7s-user  btn-icon-wrapper mb-2"></i>Seu Perfil
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-secondary">
                    <i class="pe-7s-config btn-icon-wrapper mb-2"></i>
                    <b>Configurações</b>
                  </button>
                </div>
              </div>
            </div> -->
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '@/service/coreService';
import { mapState } from 'vuex';

export default {

};
</script>

<style scoped>
#profile-menu-toggle:hover {
  text-decoration: none;
}
</style>