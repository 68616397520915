<template>
  <div class="app-footer">
    <div class="app-footer__inner">
      <div class="app-footer-left">
        <div class="text-center text-grey opacity-6">Copyright &copy; 2023 EasyTravel, CodeScript Tecnologia</div>
        <!-- <FooterDots /> -->
      </div>
      <!-- <div class="app-footer-right">
        <FooterMenu />
      </div> -->
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

library.add(faEllipsisV);
export default {
  name: "Footer",
};
</script>
