<template>
<div>
  <PageTitle :heading="heading" :subheading="subheading" :createNewButton="false"></PageTitle>
  <div v-if="passengers.length > 0" class="main-card card mb-3 mx-auto" style="width: 700px">
    <div class="card-body">
      <div v-if="loadingPassengers" class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-else class="list-group" flat accordion>
        <CustomTile 
          v-for="passenger in passengers"
          :key="passenger.id"
          :title="passenger.name" 
          :subtitle="passenger.email"
          :imageFromEntity="'passengers'"
          :imageName="passenger.id"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import PageTitle from '@/Layout/Components/PageTitle2.vue'
import CustomTile from '@/components/CustomTile.vue'

import service from '@/service/coreService';

export default {
  name: 'PassengersPage',
  components: {
    PageTitle,
    CustomTile
  },
  data() {
    return {
      heading: this.$t('menu.passengers'),
      subheading: '',
      passengers: [],
      loadingPassengers: false,
    }
  },
  methods: {
    getPassengers() {
      this.loadingPassengers = true;
      service.passengers.getPassengers().then(response => {
        this.passengers = response.data;
      })
      .finally(() => {
        this.loadingPassengers = false;
      });
    }
  },
  mounted() {
    this.getPassengers();
  }
}
</script>

<style>

</style>