export const util = {
  toBase64: file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let finalResult = reader.result.split(',')[1];
      resolve(finalResult);
    };
    reader.onerror = error => reject(error);
  })
}