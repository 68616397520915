<template>
  <v-main class="mt-5">
    <v-row>
      <v-col cols="6">
        <v-card outlined>
          <v-card-subtitle>Próximas viagens de {{ driver.name }}</v-card-subtitle>
          <v-card-text>
            <v-list
              style="max-height: 250px"
              class="overflow-y-auto"
            >
              <v-list-item v-for="travel in travels" :key="travel.id">
                <v-list-item-content>
                  <v-list-item-title>{{ travel.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ travelTypeLabel[travel.travelType] }}
                    {{ travel.date }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>Veículo</v-list-item-action-text>
                  {{ travel.vehicle.name }}
                </v-list-item-action>
                <v-list-item-action>
                  <v-avatar>
                    <v-img src="https://passageirodeprimeira.com/wp-content/uploads/2022/12/passageirodeprimeira.com-azul-amplia-horarios-de-onibus-entre-sao-paulo-e-campinas-onibus-azul.jpg"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-spacer />
      <v-col cols="2">
        <v-btn
          color="red"
          block
          outlined
          @click="deleteDriver"
        >
          excluir
          <v-icon right>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row> -->
  </v-main>
</template>

<script>
import service from '@/service/coreService';

export default {
  name: 'DriverDetails',
  props: {
    driver: Object,
  },
  data() {
    return {
      show: false,
      travels: [],
      travelTypeLabel: {
        GOING: 'IDA',
        RETURNING: 'VOLTA',
      }
    }
  },
  methods: {
    delete() {
      // service.drivers.deleteDriver(this.driver.id).then(() => {
      //   this.$emit('deleteDriver', this.driver.id);
      // });
    },
    getTravels() {
      const fromDate = new Date(Date.now());
      const toDate = new Date();
      toDate.setDate(fromDate.getDate() + 7);

      const from = fromDate.toISOString().substring(0, 10);
      const to = toDate.toISOString().substring(0, 10);

      service.drivers.getTravels(this.driver.id, from, to).then(response => {
        const roundTravels = response.data;
        const travels = [];
        roundTravels.forEach(rt => {
          if(rt.going.driver.id === this.driver.id) {
            rt.going.date = rt.date;
            travels.push(rt.going);
          }
          if(rt.returning.driver.id === this.driver.id) {
            rt.returning.date = rt.date;
            travels.push(rt.returning);
          }
        });
        this.travels = travels;
      })
    }
  },
  mounted() {
    this.getTravels();
  }
}
</script>
