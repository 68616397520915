<template>
  <div>
    <PageTitle :heading="heading" :subheading="subheading" @onCreateNew="openVehicleDialog({})"></PageTitle>
    <VehicleDialog ref="vehicleDialog" @saveVehicle="getVehicles"/>
    <DeleteVehicleDialog ref="deleteVehicleDialog" @deleteVehicle="deleteVehicle"/>
    <v-main class="text-center mb-5">
      <v-progress-circular v-show="loadingVehicles" indeterminate></v-progress-circular>
      <v-row>
        <v-col 
          cols="4"
          v-for="vehicle in vehicles"
          :key="vehicle.id"
        >
          <VehicleCard 
            :vehicle="vehicle"
            @openVehicleDialog="openVehicleDialog"
            @openDeleteVehicleDialog="openDeleteVehicleDialog"
          />
        </v-col>
        <v-snackbar v-model="showSnackbar">{{ $t('vehicles.delete_error') }} </v-snackbar>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import PageTitle from '@/Layout/Components/PageTitle2.vue';
import VehicleDialog from './VehicleDialog.vue';
import VehicleCard from './VehicleCard.vue'
import DeleteVehicleDialog from './DeleteVehicleDialog.vue';
import service from '@/service/coreService';

export default {
  name: 'VehiclesPage',
  components: {
    PageTitle,
    VehicleDialog,
    VehicleCard,
    DeleteVehicleDialog
  },
  data() {
    return {
      heading: this.$t('menu.vehicles'),
      subheading: '',
      vehicles: [],
      loadingVehicles: false,
      showSnackbar: false,
    }
  },
  methods: {
    getVehicles() {
      this.loadingVehicles = true;
      service.vehicles.getVehicles().then(response => {
        this.vehicles = response.data;
      })
      .finally(() => {
        this.loadingVehicles = false;
      }); 
    },
    deleteVehicle(vehicleId) {
      service.vehicles.deleteVehicle(vehicleId).then(() => {
        this.vehicles.splice(this.vehicles.findIndex(v => v.id == vehicleId), 1);
      })
      .catch(error => {
        if(!!error.response.data && !!error.response.data.data.vehicleId) {
          this.showSnackbar = true;
        }
      });
    },
    openDeleteVehicleDialog(vehicle) {
      this.$refs.deleteVehicleDialog.openDialog(vehicle);
    },
    openVehicleDialog(vehicle, image) {
      this.$refs.vehicleDialog.openDialog(vehicle, image);
    },
  },
  mounted() {
    this.getVehicles();
  }
}
</script>
